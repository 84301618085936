<template>
  <div class="subscribe-combine-edit">
    <PageTitle class="page-title" :title="title" icon="chevron_left" hideBtn @iconClick="backToList" />
    <div v-if="loading" v-loading="loading" />
    <template v-if="!loading">
      <SubscribeInfoBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :combineData="combineData"
      />
      <SubscribeCombineBlock
        :FormsContext="FormsContext"
        :combineData.sync="combineData"
        @updateEquities="updateEquities"
      />
    </template>

    <PageFixedFooter @cancel="backToList" @confirm="onSubmit" />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, set } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import SubscribeInfoBlock from './components/SubscribeInfoBlock.vue'
import SubscribeCombineBlock from './components/SubscribeCombineBlock.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FormsContext from './formsContext'
import { FindSubscribeEquityPack, CreateSubscribeEquityPack, UpdateSubscribeEquityPack } from '@/api/subscribe'
import { useShop } from '@/use/shop'
import { get, map } from 'lodash'

export default defineComponent({
  name: 'SubscribeCombineEdit',
  components: {
    PageFixedFooter,
    SubscribeInfoBlock,
    SubscribeCombineBlock,
    PageTitle,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const combineId = computed(() => route.params.id)
    const title = computed(() => {
      if (route.query.edit) return '編輯品項組合'
      if (route.query.view) return '查看品項組合'
      return '新增品項組合'
    })
    const combineData = ref({
      name: null,
      equities: null,
    })
    const loading = ref(false)

    const backToList = () => {
      router.push({
        name: 'SubscribeCombineList',
      })
    }
    const updateEquities = (data) => {
      set(combineData.value, 'equities', data)
    }
    const findSubscribeEquitypack = async () => {
      loading.value = true
      const [res, err] = await FindSubscribeEquityPack({
        shopId: shopId.value,
        id: combineId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      combineData.value = res
    }

    const createSubscribeEquityPack = async (payload) => {
      loading.value = true
      const [, err] = await CreateSubscribeEquityPack({
        shopId: shopId.value,
        ...payload,
      })
      if (err) throw err
      window.$message.success('新增成功!')
    }

    const updateSubscribeEquityPack = async (payload) => {
      loading.value = true
      const [, err] = await UpdateSubscribeEquityPack({
        shopId: shopId.value,
        id: combineId.value,
        ...payload,
      })
      if (err) throw err
      window.$message.success('更新成功！')
    }

    const onSubmit = async () => {
      if (route.query.view) return backToList()
      const allPass = await FormsContext.validateAll()
      if (allPass) {
        const data = FormsContext.composFormData()
        let equities = []
        if (get(combineData.value, 'equities')) {
          equities = map(get(combineData.value, 'equities'), (item) => {
            return {
              id: item.id || undefined,
              name: item.name,
              price: item.price || null,
              description: item.description || null,
            }
          })
        } else {
          window.$message.error('請新增品項')
          return
        }
        const postBody = {
          ...data,
          equities,
        }

        try {
          if (!combineId.value) await createSubscribeEquityPack(postBody)
          if (combineId.value) await updateSubscribeEquityPack(postBody)
          backToList()
        } catch (error) {
          window.$message.error(error)
          loading.value = false
        }
      }
    }

    onMounted(() => {
      if (combineId.value) findSubscribeEquitypack()
    })

    return {
      backToList,
      onSubmit,
      loading,
      FormsContext,
      combineData,
      title,
      updateEquities,
    }
  },
})
</script>

<style lang="postcss" scoped>
.subscribe-combine-edit {
  @apply flex flex-col gap-[20px];
}
.page-title {
  @apply mb-0
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
