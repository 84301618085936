<template>
  <div class="external-transaction-activity">
    <PageTitle title="品項內容" btn="新增" :hideBtn="formData.isSelect" @btnClick="handleClickItem('create')" />
    <SubscribeCombineItemTable v-loading="loading.table" :isSelect="formData.isSelect" :tableData="formData.equities" @handleClickItem="handleClickItem" />
    <EditSubscribeCombineItemDialog
      v-if="SubscribeCombineItemDialog"
      :selectItem="selectItem"
      :type="subscribeCombineItemDialogType"
      @close="SubscribeCombineItemDialog = false"
      @update="updateSubscribeCombineItem"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, nextTick, reactive } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import EditSubscribeCombineItemDialog from './EditSubscribeCombineItemDialog.vue'
import SubscribeCombineItemTable from './SubscribeCombineItemTable.vue'
import { noEmptyRules } from '@/validation'
import { get, findIndex } from 'lodash'

export default defineComponent({
  name: 'SubscribeCombineBlock',
  components: {
    PageTitle,
    SubscribeCombineItemTable,
    EditSubscribeCombineItemDialog,
  },
  props: ['FormsContext', 'combineData'],
  setup (props, { emit }) {
    const formRef = ref(null)
    const formData = reactive({
      equities: [],
      isSelect: null,
    })
    const formRules = {
      equities: [noEmptyRules()],
    }

    const loading = reactive({
      table: false,
    })
    const SubscribeCombineItemDialog = ref(false)
    const subscribeCombineItemDialogType = ref('create')
    const selectItem = ref(null)
    const handleClickItem = (type, row) => {
      if (type === 'delete') {
        loading.table = true
        selectItem.value = row
        const index = findIndex(formData.equities, (item) => item.name === get(selectItem.value, 'name'))
        formData.equities.splice(index, 1)
        loading.table = false
        emit('updateEquities', formData.equities)
      }
      if (type === 'create') {
        selectItem.value = null
        SubscribeCombineItemDialog.value = true
      }
      if (type === 'edit' || type === 'view') {
        selectItem.value = row
        SubscribeCombineItemDialog.value = true
      }

      subscribeCombineItemDialogType.value = type
    }

    const updateSubscribeCombineItem = async (type, data) => {
      loading.table = true
      if (type === 'create') formData.equities.push(data)
      if (type === 'edit') {
        const index = findIndex(formData.equities, (item) => item.name === data.name)
        formData.equities.splice(index, 1, data)
      }
      loading.table = false
      emit('updateEquities', formData.equities)
    }
    const syncData = async () => {
      loading.table = true
      const data = props.combineData
      formData.equities = get(data, 'equities')
      formData.isSelect = !!get(data, 'planEquityPacks.length')
      loading.table = false
    }

    onMounted(async () => {
      await nextTick()
      if (get(props.combineData, 'id')) await syncData()
    })

    return {
      formRef,
      formData,
      formRules,
      loading,
      SubscribeCombineItemDialog,
      subscribeCombineItemDialogType,
      selectItem,
      handleClickItem,
      updateSubscribeCombineItem,
    }
  },
})
</script>
